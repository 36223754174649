import React, {useState, useEffect, useMemo, useContext} from 'react';
import "./client-stories.scss";
import { Link } from "react-router-dom";
import { AppContext } from '../../services/translationContext';
import { Loader } from '../../components/loader/loader';

const api = process.env.REACT_APP_URL_API;

export const ClientStories = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  document.title = `${TranslationsService.labels(`client_stories`)} | ${TranslationsService.getMainInfoCompany('name')}`;
  const [filterClient, setFilterClient] = useState({});
  const [listFilterSel, setListFilterSel] = useState([]);
  const [listClientStories, setListClientStories] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useMemo(() => {
    setShowLoader(true);
    fetch(`${api}/content-public?id=client-stories&language=${TranslationsService.getCurrentLanguage()}`)
    .then(response => response.json()).then(data => {
      setListClientStories(data.data);
      setShowLoader(false);
    });
  }, [TranslationsService]);

  useEffect(() => {
    let industriesList = TranslationsService.labels(`industries`);
    for (var i = 0; i < Object.keys(industriesList).length; i++) 
    {
      industriesList[Object.keys(industriesList)[i]].isActive = false;
    }
    setFilterClient(industriesList);
  },[TranslationsService]);

  const onSelectedFilter = (filterSel) => {
    setShowLoader(true);
    let _tmpFilterList =  {...filterClient};
    _tmpFilterList[filterSel].isActive = !_tmpFilterList[filterSel].isActive;
    setFilterClient(_tmpFilterList);

    let _listFilterSel = [...listFilterSel];
    if(_tmpFilterList[filterSel].isActive){
      _listFilterSel.push(filterSel);
    }
    else{
      let _index = _listFilterSel.indexOf(filterSel);
      _listFilterSel.splice(_index, 1);
    }
    setListFilterSel(_listFilterSel);
    console.log(listFilterSel);

    let stringFilter = '';
    _listFilterSel.forEach((_filter, i)=>{
      stringFilter += `${_filter}|`;
    });

    fetch(`${api}/content-public?id=client-stories&language=${TranslationsService.getCurrentLanguage()}&industries=${stringFilter}`)
    .then(response => response.json()).then(data => {
      setListClientStories(data.data);
      setShowLoader(false);
    });
  }

  const clearFilter = () =>{
    setShowLoader(true);
    let filterList =  {...filterClient};
    for (var i = 0; i < Object.keys(filterList).length; i++) 
    {
      filterList[Object.keys(filterList)[i]].isActive = false;
    }
    setFilterClient(filterList);
    setListFilterSel([]);
    fetch(`${api}/content-public?id=client-stories&language=${TranslationsService.getCurrentLanguage()}`)
    .then(response => response.json()).then(data => {
      setListClientStories(data.data);
      setShowLoader(false);
    });
  }

  return (
    <div className="section-home light">
      <section className='main-hero syd-hero position-relative' style={{backgroundImage:`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/hero_sections/${TranslationsService.labels('hero_sections.client_stories.img_path')})`}}>
        <div className='my-auto mx-0'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.insights.label`)}</Link>
          </p>
          <h2 className='syd-title light text-uppercase'>{TranslationsService.labels(`client_stories`)}</h2>
          <p className='dark-mode-text fs-1 m-0'>{TranslationsService.labels(`hero_sections.client_stories.text`)}</p>
        </div>
      </section>

      {
        showLoader &&
        <Loader />
      }

      <div className='p-3'>
        <p className='fw-bold fs-5 d-flex'>{TranslationsService.labels('filter_by')} </p>
        <div className='d-flex flex-wrap'>
          {
            Object.keys(filterClient).map((_filter, i) => (
              <div key={filterClient[_filter].title} className={`syd-chips ${filterClient[_filter].isActive ? 'active':''} transition-03s-eio px-3 py-2 me-2 mb-2`} onClick={() => onSelectedFilter(_filter)}>
                <p className='m-0'>{filterClient[_filter].title}</p>
              </div>
            ))
          }
        </div>
        <div className={`btn-clear-filter ${listFilterSel.length > 0 ? 'visible':'invisible'} py-1 px-2 d-flex align-items-center gap-1`} onClick={clearFilter}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          <span>Clear</span>
        </div>

        <div className='p-1'>
          {/* <div className='row gap-3 gap-lg-0'>
            {
              listClientStories.map((clientStory,i) => (
                <div className='col-sm-12 col-lg-4 pb-4' key={i}>
                  <Link to={`/insights/client-stories/${clientStory.url_path}`} className='text-deco-none tile-client-story'>
                    <div className='syd-box small flat d-flex position-relative cards-cl-sto'>
                      <div className='bg-client-stories' style={{backgroundImage:`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${clientStory.image})`}}></div>
                      <div className='body-stories-sect d-flex flex-column h-100 w-100'>
                        <h4 className="syd-title light">{clientStory.title}</h4>
                        <p className='syd-paragraph fs-5 pt-2'>{clientStory.description}</p>
                        <div className='row pt-5 mt-auto'>
                          <h5 className='dark-mode-text m-0 fs-6 d-flex flex-column flex-lg-row justify-content-between'>
                            <span>{TranslationsService.labels(`services.${clientStory.service.split(',')[0]}.title`)} - {TranslationsService.labels(`services.${clientStory.service.split(',')[0]}.${clientStory.service.split(',')[1]}.title`)}</span>
                            <span className='text-end'>{TranslationsService.labels(`industries.${clientStory.industries}.title`)}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            }
          </div> */}

          <div className='row gap-3 gap-lg-0'>

          {
            listClientStories?.map((clientStory,i) => (
              <div className='col-sm-12 col-lg-4 my-2' key={i}>
                <Link to={`/insights/client-stories/${clientStory.url_path}`} className='text-deco-none tile-client-story'>
                  <div className='syd-box h-100 flat d-flex position-relative p-0 overflow-hidden cards-cl-sto'>
                    <img src={`https://d3t3s6w5yvhc3g.cloudfront.net/images/${clientStory.image}`} className='insights-image transition-03s-eio client-story-tile-img' alt='Hero Client Story'></img>
                    <div className='body-stories-sect d-flex flex-column h-100 w-100 p-4 bkg-tile-client-story'>
                      <h4 className="syd-title light">{clientStory.title}</h4>
                      <p className='syd-paragraph fs-5 pt-2'>{clientStory.description}</p>
                      <div className='row pt-5 mt-auto'>
                        <h5 className='dark-mode-text m-0 fs-6 d-flex flex-column flex-lg-row justify-content-between'>
                          <span>{TranslationsService.labels(`services.${clientStory.service.split(',')[0]}.title`)} - {TranslationsService.labels(`services.${clientStory.service.split(',')[0]}.${clientStory.service.split(',')[1]}.title`)}</span>
                          <span>{TranslationsService.labels(`industries.${clientStory.industries}.title`)}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          }

</div>
        </div>
      </div>
    </div>
  );
};

