import React, { useContext, useMemo, useState } from 'react';
import "./client-stories-detail.scss";
import { Link, useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { AppContext } from '../../services/translationContext';

const api = process.env.REACT_APP_URL_API;

export const ClientStoriesDetail = () => {
  let { story_id } = useParams();
  const { services: {TranslationsService} } = useContext(AppContext);
  const [storyInfo, setStoryInfo] = useState([]);

  useMemo(() => {
    fetch(`${api}/content-public?url_path=${story_id}`)
    .then(response => response.json())
    .then(data => {
      setStoryInfo(data.data[0]);
      document.title = `${TranslationsService.labels(`client_stories`)} - ${data.data[0].title} | ${TranslationsService.getMainInfoCompany('name')}`;
    });
  }, [TranslationsService, story_id]);

  const getHtmlContent = () => {
    try{
      let htmlText = Buffer.from(storyInfo.content, "base64");
      return htmlText;
    }
    catch(e){
      return '';
    }

  }

  return (
    <div className="section-home light">
      <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage: storyInfo.image ?`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${storyInfo.image})`:''}}>
        <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.insights.label`)}</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights/client-stories' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`client_stories`)}</Link>
          </p>
          <h2 className='syd-title light text-uppercase'>{storyInfo.title}</h2>
          <p className='dark-mode-text fs-1 m-0'>{storyInfo.description}</p>
        </div>
      </section>

      {
        storyInfo.service &&
        <div className='d-flex flex-wrap p-3'>
          <Link to={`/industries/${storyInfo.industries}`} className='syd-chips transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`industries.${storyInfo.industries}.title`)}</p>
          </Link>
          <Link to={`/services/${storyInfo.service.split(',')[0]}`} className='syd-chips  transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.title`)}</p>
          </Link>
          <Link to={`/services/${storyInfo.service.split(',')[0]}/${storyInfo.service.split(',')[1]}`} className='syd-chips  transition-03s-eio px-3 py-2 me-2 mb-2 text-deco-none syd-black breadcrumb-detail'>
            <p className='m-0'>{TranslationsService.labels(`services.${storyInfo.service.split(',')[0]}.${storyInfo.service.split(',')[1]}.title`)}</p>
          </Link>
        </div>
      }

      <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-75 p-3 area-text-cs'></div>

    </div>
  );
};
