import React, { useContext, useMemo, useState } from 'react';
import "./news-detail.scss";
import { Link, useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { AppContext } from '../../services/translationContext';
import { Loader } from '../../components/loader/loader';

const api = process.env.REACT_APP_URL_API;

export const NewsDetail = () => {
  let { news_id } = useParams();
  const { services: {TranslationsService} } = useContext(AppContext);
  const [newsInfo, setNewsInfo] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useMemo(() => {
    setShowLoader(true);
    fetch(`${api}/content-public?url_path=${news_id}`)
    .then(response => response.json())
    .then(data => {
      setNewsInfo(data.data[0]);
      document.title = `${TranslationsService.labels(`client_stories`)} - ${data.data[0].title} | ${TranslationsService.getMainInfoCompany('name')}`;
      setShowLoader(false);
    });
  }, [TranslationsService, news_id]);

  const getHtmlContent = () => {
    try{
      let htmlText = Buffer.from(newsInfo.content, "base64");
      return htmlText;
    }
    catch(e){
      return '';
    }

  }

  return (
    <div className="section-home light">
      {
        showLoader &&
        <Loader />
      }
      <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage:newsInfo.image?`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${newsInfo.image})`:''}}>
        <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/sydea-blog' className='text-deco-none dark-mode-text transition-03s-eio'>Blog</Link>
            {/* &nbsp;&#9656;&nbsp;
            <Link to='/insights' className='text-deco-none dark-mode-text transition-03s-eio'>{TranslationsService.labels(`menu.insights.label`)}</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/insights/news' className='text-deco-none dark-mode-text transition-03s-eio'>News</Link> */}
          </p>
          <h2 className='syd-title light text-uppercase'>{newsInfo.title}</h2>
          <p className='dark-mode-text fs-1 m-0'>{newsInfo.description}</p>
        </div>
      </section>

      <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-100 p-3 area-text-cs'></div>
    </div>
  );
};

