import React, { useContext, useState } from 'react';
import "./careers.scss";
import { AppContext } from '../../services/translationContext';

const api = process.env.REACT_APP_URL_API;

export const Careers = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  document.title = `${TranslationsService.labels(`menu.careers.label`)} | ${TranslationsService.getMainInfoCompany('name')}`;
  
  const formatTextWithBr = (text) => {
    let textArea = '';
    text.split('\n').map((line, index) => (
      textArea +=`${line}<br />`
    ));
    return textArea;
  };

  const initialFormData = {
    name: '',
    mailSubject: '',
    email: '',
    bodyMail: '',
    isChecked: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    const mailForm = TranslationsService.getMailTemaplateCareers();
    event.preventDefault();
    const { name, mailSubject, email, bodyMail } = formData;

    let body_mail = mailForm.template;
    const variables = { name, mailSubject, email, bodyMail };

    Object.keys(variables).forEach(key => {
      const regex = new RegExp('\\${' + key + '}', 'g');
      if (body_mail.indexOf('${' + key + '}') !== -1) {
        if (key === 'bodyMail') {
          const formattedValue = formatTextWithBr(variables[key]);
          body_mail = body_mail.replace(regex, formattedValue);
        } else {
          body_mail = body_mail.replace(regex, variables[key]);
        }
      } else {
        console.error(`La stringa iniziale non contiene ${key}`);
      }
    });

    let buffer = Buffer.from(body_mail);
    let base64String = buffer.toString('base64');

    let _body = {
      to: [email],
      subject: `Careers: Confirmation of Request Receipt - ${mailSubject}`,
      body: base64String,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-origin-verify': 'BB6a2U8jgygBYCqo8yfW8HT9P8EKafR2' },
      body: JSON.stringify(_body)
    };
    fetch(`${api}/mail`, requestOptions).then((response) => {
      setFormData(initialFormData);
    })
    .catch((error) => {
      setFormData(initialFormData);
    });
  };

  return (
    <div className="section-home light">
       <section className='main-hero syd-hero position-relative' style={{backgroundImage:`url(${TranslationsService.labels('hero_sections.careers.img_path')})`}}>
        <div className='my-auto mx-0'>
          <h2 className='syd-title light text-uppercase'>{TranslationsService.labels(`menu.careers.label`)}</h2>
          {
            TranslationsService.labels('hero_sections.careers.text') &&
            <p className='dark-mode-text fs-1 m-0'>{TranslationsService.labels('hero_sections.careers.text')}</p>
          }
        </div>
      </section>

      {
        TranslationsService.labels(`careers.our_work`) && Object.keys(TranslationsService.labels(`careers.our_work`)).length > 0 &&
        <section className='p-3 bg-main-color'>
          <div className='row'>
            <div className='col-sm-12 col-lg-6 p-5 d-flex flex-column justify-content-center'>
              <h3 className='fw-bold fs-1'>{TranslationsService.labels(`careers.our_work.title`)}</h3>
              <p className='fs-5' dangerouslySetInnerHTML={{ __html: TranslationsService.labels(`careers.our_work.text`) }}></p>
            </div>
            <div className='col-sm-12 col-lg-6 d-flex flex-column justify-content-center'>
              <img src={require('../../assets/careers/our_work.jpg')} className='img-fluid' alt={TranslationsService.labels(`careers.our_work.title`)}></img>
            </div>
          </div>
        </section>
      }

      {
        TranslationsService.labels(`careers.our_values`) && Object.keys(TranslationsService.labels(`careers.our_values`)).length > 0 &&
        <section className='p-3 light-mode-bg'>
          <div className='row light-mode-text'>
            <div className='col-sm-12 col-lg-6 d-flex flex-column justify-content-center'>
              <img src={require('../../assets/careers/our_values.png')} className='img-fluid' alt={TranslationsService.labels(`careers.our_values.title`)}></img>
            </div>
            <div className='col-sm-12 col-lg-6 p-5 d-flex flex-column justify-content-center'>
            <h3 className='fw-bold fs-1'>{TranslationsService.labels(`careers.our_values.title`)}</h3>
              <p className='fs-5' dangerouslySetInnerHTML={{ __html: TranslationsService.labels(`careers.our_values.text`) }}></p>
            </div>
          </div>
        </section>
      }

      {
        TranslationsService.labels(`careers.who_are_we_looking_for`) && Object.keys(TranslationsService.labels(`careers.who_are_we_looking_for`)).length > 0 &&
        <section className='p-3 dark-mode-bg'>
          <div className='row dark-mode-text'>
            <div className='col-sm-12 col-lg-6 p-5 d-flex flex-column justify-content-center'>
              <h3 className='fw-bold fs-1 dark-mode-title'>{TranslationsService.labels(`careers.who_are_we_looking_for.title`)}</h3>
              <p className='fs-5 dark-mode-text' dangerouslySetInnerHTML={{ __html: TranslationsService.labels(`careers.who_are_we_looking_for.text`) }}></p>
            </div>
            <div className='col-sm-12 col-lg-6 d-flex flex-column justify-content-center'>
              <img src={require('../../assets/careers/we_want_u.png')} className='img-fluid' alt={TranslationsService.labels(`careers.who_are_we_looking_for.title`)}></img>
            </div>
          </div>
        </section>
      }
      
      {
        TranslationsService.showContactFormCareers() &&
        <div className="p-3">
        <div className="box-contact m-auto p-4">
          <h2 className="syd-title dark fw-bold text-uppercase py-3">
            {TranslationsService.labels(`menu.contact-us.label`)}
          </h2>
          <form onSubmit={handleSubmit} className="width-form" id="contact-form">
            <div className="my-3">
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={`${TranslationsService.labels('formName')}*`}
                className="w-100 p-2 input-contact"
              />
            </div>
            <div className="my-3">
              <input
                required
                type="text"
                name="mailSubject"
                value={formData.mailSubject}
                onChange={handleChange}
                placeholder={`${TranslationsService.labels('formSubject')}*`}
                className="w-100 p-2 input-contact"
              />
            </div>
            <div className="my-3">
              <input
                required
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={`${TranslationsService.labels('formMail')}*`}
                className="w-100 p-2 input-contact"
              />
            </div>
            <div className="my-3">
              <textarea
                required
                name="bodyMail"
                value={formData.bodyMail}
                onChange={handleChange}
                rows={5}
                placeholder={TranslationsService.labels(`formComment`)}
                className="w-100 p-2 input-contact"
              />
            </div>
            <label className="my-3 syd-checkbox-label">
              <input className="syd-checkbox" type="checkbox" required
                name="isChecked"
                checked={formData.isChecked}
                onChange={handleChange}/>
              {TranslationsService.labels(`textSubmitForm`)}
            </label>
            <button type="submit" className="syd-button m-0 mb-3 px-5">
              {TranslationsService.labels(`buttonSubmitForm`)}
            </button>
          </form>
        </div>
      </div>
      }

    </div>
  );
};

