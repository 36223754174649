import React, {useContext} from 'react';
import './client-stories.scss';
import { NavLink, Link } from "react-router-dom";
import { AppContext } from '../../../services/translationContext';

export const ClientStoriesSection = () => {
  const { services: {TranslationsService} } = useContext(AppContext);
  
  return (
    <div className='syd-bg-dark'>
      <h2 className="syd-title light fw-bold text-uppercase pt-5 px-3">{TranslationsService.labels(`client_stories`)}</h2>
      {
        TranslationsService.labels(`hero_sections.client_stories.text`) &&
        <p className='syd-paragraph p-3 fs-4'>{TranslationsService.labels(`hero_sections.client_stories.text`)}</p>
      }
      <div className='p-3 d-flex flex-column'>
        <div className='row gap-3 gap-lg-0'>

          {
            TranslationsService.labels(`client_stories_sect`).map((_cStory, i) => (
              <div className='col-sm-12 col-lg-4' key={i}>
                <Link to='/insights/client-stories/dropsa' className='text-deco-none tile-client-story'>
                  <div className='syd-box h-100 flat d-flex position-relative p-0 overflow-hidden'>
                    {/* <div className='bg-client-stories' style={{backgroundImage:`url(${_cStory.image_url})`}}></div> */}
                    <img src={_cStory.image_url} className='insights-image transition-03s-eio client-story-tile-img'></img>
                    <div className='body-stories-sect d-flex flex-column h-100 w-100 p-4 bkg-tile-client-story'>
                      <h4 className="syd-title light">{_cStory.title}</h4>
                      <p className='syd-paragraph fs-5 pt-2'>{_cStory.desc}</p>
                      <div className='row pt-5 mt-auto'>
                        <h5 className='dark-mode-text m-0 fs-6 d-flex flex-column flex-lg-row justify-content-between'>
                          <span>{TranslationsService.labels(`services.${_cStory.bu_area}.title`)} - {TranslationsService.labels(`services.${_cStory.bu_area}.${_cStory.bu_service}.title`)}</span>
                          <span>{TranslationsService.labels(`industries.${_cStory.industry}.title`)} </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          }

        </div>

        <NavLink to="/insights/client-stories" className="fw-bold fs-4 ref-syd-nav transition-03s-eio dark-mode-text px-2 py-3">
          {TranslationsService.labels('view_all_stories')}
          <svg viewBox="0 0 7.48 11.59" className='icon-arrow ms-2'><polyline className="arrow-all transition-03s-eio" stroke='currentColor' points="1 1 6.48 5.8 1 10.59"/></svg>
        </NavLink>
      </div>
    </div>
  );
};